import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/index'; // Make sure this path is correct
import store from './store';
import './index.css'; // Assuming you have a CSS file to import

// Create the Vue application instance
const app = createApp(App);

// Use the router and store with the application
app.use(router);
app.use(store);

// Initialize the authentication state before mounting the app
store.dispatch('initAuthState').then(() => {
  // Mount the app to the DOM
  app.mount('#app');
});