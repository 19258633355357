import { createStore } from 'vuex';
import { auth } from './firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

const firestore = getFirestore();

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    apiUsageCount: 0, // Initialize the API usage count
  },
  getters: {
    user(state) {
      return state.user;
    },
    apiUsageCount(state) { // Getter for the API usage count
      return state.apiUsageCount;
    }
  },
  mutations: {
    SET_USER(state, payload) {
      state.user.data = payload;
      state.user.loggedIn = Boolean(payload); // Ensure loggedIn is a boolean
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_API_USAGE_COUNT(state, count) { // Mutation to set the API usage count
      state.apiUsageCount = count;
    }
  },
  actions: {
    async register(context, { email, password, name }) {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      if (response) {
        // Create a user document in Firestore
        await setDoc(doc(firestore, 'users', response.user.uid), {
          displayName: name,
          email: email,
          createdAt: new Date(),
          api_usage_count: 50 
          // ... any other initial user data
        });
  
        context.commit('SET_USER', {
          displayName: response.user.displayName,
          email: response.user.email
        });
        await response.user.updateProfile({ displayName: name });
      } else {
        throw new Error('Unable to register user');
      }
    },
    async logIn(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        context.commit('SET_USER', {
          displayName: response.user.displayName,
          email: response.user.email
        });
      } else {
        throw new Error('Login failed');
      }
    },
    async logOut(context) {
      await signOut(auth);
      context.commit('SET_USER', null);
    },
    async fetchApiUsageCount(context) {
      const user = auth.currentUser; // Get the currently logged-in user
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        try {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            context.commit('SET_API_USAGE_COUNT', data.api_usage_count || 0); // Update the API usage count
          } else {
            console.log('No user document found!');
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
      }
    },
    initAuthState(context) {
      onAuthStateChanged(auth, user => {
        if (user) {
          // User is signed in, set the user data
          context.commit("SET_USER", {
            displayName: user.displayName,
            email: user.email
          });
          context.commit("SET_LOGGED_IN", true);
          context.dispatch("fetchApiUsageCount");
        } else {
          // User is signed out, reset the user data
          context.commit("SET_USER", null);
          context.commit("SET_LOGGED_IN", false);
        }
      });
    },
  }
});

// Export the store
export default store;