
<template>
<header>
  <nav class="border-gray-200 px-4 lg:px-6 py-3 bg-white mt-4 w-1400 mb-20 ml-auto mr-auto rounded-50 shadow-md">
      <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="#" class="flex items-center">
              <img src="../assets/ae-logo.png" class="mr-3 w-32" alt="App Logo" />
          </a>
          <div class="flex items-center lg:order-2">
              <a href="#" @click.prevent="signOut" class="text-white bg-black rounded-50 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-lg px-5 lg:px-5 py-3 lg:py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Log out</a>
              <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                  <span class="sr-only">Open main menu</span>
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                  <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>
          </div>
          <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
              <ul class="flex flex-col mt-1 font-medium text-lg lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                      <a href="/" class="block py-2 pr-4 pl-3 text-purple-700 rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 text-black-300" aria-current="page">Dashboard</a>
                  </li>
                  <li>
                      <a href="https://bogumil-luc.gitbook.io/airtable/" class="block py-2 pr-4 pl-3 text-black-300 sborder-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent border-gray-700">Docs</a>
                  </li>
                  <li>
                      <a href="https://easl.ink/RzaLC" class="block py-2 pr-4 pl-3 text-black-300 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent border-gray-700">Pricing</a>
                  </li>
                  <li>
                      <a href="https://bogumil-luc.gitbook.io/airtable/contact" class="block py-2 pr-4 pl-3 text-black-300 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent border-gray-700">Contact</a>
                  </li>
              </ul>
          </div>
      </div>
  </nav>
</header>
</template>


<script>
import { useStore} from "vuex";
import { useRouter } from "vue-router";
import {computed} from "vue";
import { auth } from '../firebaseConfig'

export default {
name: "NavBar",
setup() {
      const store = useStore();
      const router = useRouter();
      auth.onAuthStateChanged(user => {
          store.dispatch("fetchUser", user);
      });
      const user = computed(() => {
          return store.getters.user;
      });
      const signOut = async () => {
          await store.dispatch('logOut');
          router.push('/login');
      };
      return { user, signOut };
  },
  components: {}

};
</script>
