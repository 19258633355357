<template>

<section class="bg-gray-100 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <div class="flex align-items">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="w-48 " src="../assets/ae-logo.png" alt="logo">
      </a>
    </div>
    <h1 class="text-6xl text-center mb-12">New users<span class="text-gray-400"> and old <br>ones,</span> welcome</h1>

                    <button @click="signInWithGoogle" type="submit" class="bg-black text-white flex items-center rounded-15 text-xl px-16 py-4 mb-8">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M21.35 11.1h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27c3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10c5.35 0 9.25-3.67 9.25-9.09c0-1.15-.15-1.81-.15-1.81"/></svg>
  <span class="ml-4 text-xl">Continue with Google</span>
</button>




    <p class="text-gray-500 text-md text-center w-1/3">By clicking "Continue with Google", you acknowledge that you have read and understood, and agree <a style="text-decoration: underline;" href="https://bogumil.notion.site/Privacy-Policy-6f879a564da34cc4a8d06bff2a58720a?pvs=4">Privacy Policy</a>.</p>

    <!-- <div class="flex flex-col items-center justify-center space-y-1 p-4">
    <a href="#" class="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
        <img class="w-14 h-14 mr-2" src="../assets/logo_app.png" alt="logo">
    </a>
    <a href="#" class="text-2xl font-semibold text-gray-900 dark:text-white">
        Airtable Exporter
    </a>
    </div> -->

<!--               <form class="space-y-4 md:space-y-6" action="#" @submit.prevent="Login">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" value autofocus v-model="email">
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" v-model="password">
                  </div>
                  <div class="flex items-center justify-between">
                      <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="agreement" aria-describedby="agreement" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" v-model="termsOfUse">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="agreement" class="text-gray-500 dark:text-gray-300">Agree terms of conditions</label>
                          </div>
                      </div>
                      <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                  </div>
                  <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                  <div class="flex items-center justify-center my-4">
  <div class="flex-grow border-t border-gray-300"></div>
  <span class="flex-shrink mx-4 text-gray-300">or</span>
  <div class="flex-grow border-t border-gray-300"></div>
</div>
</form> -->
<!--                   <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet? <a href="/register" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                  </p> -->
                  <div v-if="error" class="alert alert-danger">{{error}}</div>
              <!-- </form> -->
  </div>
</section>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'

export default {
    name: "LoginComponent",
    setup() {
        const email = ref('');
        const password = ref('');
        const error = ref(null);
        const store = useStore();
        const router = useRouter();
        const Login = async () => {
            try {
                await store.dispatch('logIn', {
                    email: email.value,
                    password: password.value
                });
                router.push('/');
            }
            catch (err) {
                error.value = err.message;
            }
        };
        return { Login, email, password, error };
    },
    methods: {
        async signInWithGoogle() {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            const firestore = getFirestore();
            try {
                const result = await signInWithPopup(auth, provider);
                const user = result.user;
                // Check if user document exists
                const docRef = doc(firestore, "users", user.uid);
                const docSnap = await getDoc(docRef);
                if (!docSnap.exists()) {
                    // Create a new user document if not exists
                    await setDoc(docRef, {
                        displayName: user.displayName,
                        email: user.email,
                        createdAt: new Date(),
                        api_usage_count: 50,
                        current_valid_key:''
                    });
                }
                // Update Vuex store
                this.$store.commit('SET_USER', user);
                this.$router.push('/');
            }
            catch (error) {
                this.error = error.message; // Adjust this line to use the reactive error ref from setup
            }
        }
    },
    components:{}
};
</script>