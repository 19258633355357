<template>
  <div>
     <div v-if="isDesktop">
      <navbar></navbar>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">

  <h2 class="text-5xl mb-8">Dashboard</h2>
  <div class="flex justify-between items-center mb-32">
    <div class="flex-1 max-w-xs mx-3 bg-gray-100 rounded-50 py-4 px-6 flex items-center justify-center">
  <div class="flex flex-col items-center">
    <!-- Your vertically stacked content here -->
    <div class="text-6xl font-light mb-3">{{ apiUsageCount }}</div>
    <div class="text-lg text-gray-500">Still have</div>
  </div>
</div>
<div class="flex-1 max-w-xs mx-3 bg-gray-100 rounded-50 py-4 px-6 flex items-center justify-center">
  <div class="flex flex-col items-center">
    <!-- Your vertically stacked content here -->
    <div class="text-6xl font-light mb-3">n/a</div>
    <!-- <div class="text-6xl font-light mb-3">{{ availableApi }} -- --</div> -->
    <div class="text-lg text-gray-500">Monthly usage</div>
  </div>
</div>
<a href="https://easl.ink/RzaLC" class="flex flex-col flex-1 max-w-xs mx-3 bg-black text-white text-2xl rounded-50 py-14 px-6 shadow-md items-center justify-center">
  <div>
    Add more credits
  </div>
</a>

    </div>




    <h2 class="text-5xl mb-8">Create your API Key</h2>
<p class="text-lg mb-8">Thanks to the API key, you can use the app. Do not give out your keys to anybody. 
<br>You can create as many as you want, but only the latest one is valid.</p>
<ApiKeyGenerator></ApiKeyGenerator>
<h2 class="text-5xl mt-32 mb-8">Using <span style="color: #f0f;">Make</span>?</h2>
<p class="text-lg mb-8">Check <a style="text-decoration: underline;" href="https://bogumil-luc.gitbook.io/airtable/integrations/make">documentation</a>.</p>

<!-- 
                              <div class="card">
                                  <div v-if="user.loggedIn">

                                      <div class="card-header">Welcome, {{user.data.displayName}}</div>
                                            <div class="card-body">
                                            <ApiKeyGenerator></ApiKeyGenerator>

                                                            <section class="bg-white dark:bg-gray-900">
                                                    <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                                                      <h1 class="text-4xl pb-5 font-bold">Your API usage</h1>
                                                        <dl class="grid max-w-screen-md gap-8 mx-auto text-gray-900 sm:grid-cols-2 dark:text-white">
                                                            <div class="flex flex-col items-center justify-center">
                                                                <dt class="mb-2 text-3xl md:text-4xl font-extrabold">  {{ apiUsageCount }}</dt>
                                                                <dd class="font-light text-gray-500 dark:text-gray-400">Still have</dd>
                                                            </div>
                                                             <div class="flex flex-col items-center justify-center bg-primary-100 p-4">
                                                                <dt class="mb-2 text-3xl md:text-4xl font-extrabold">{{availableApi}}</dt>
                                                                <dd class="font-light text-gray-500 dark:text-gray-400">Still have</dd>
                                                            </div> 
                                                         <div class="flex flex-col items-center justify-center">
                                                                <dt class="mb-2 text-3xl md:text-4xl font-extrabold">4M+</dt>
                                                                <dd class="font-light text-gray-500 dark:text-gray-400">organizations</dd>
                                                            </div> 
                                                        </dl>
                                                    </div>
                                                  </section>
                                            </div>
                                              
                                    </div>
                              </div> -->
      </div>
    </div>
  </div>
<Appfooter></Appfooter>
    
    </div>
     <div v-else>
      <div class="flex justify-center items-center h-screen">
     <p class="text-3xl text-black">Visit this site via desktop</p>
 </div>
     </div>
   </div>
</template>
<script>
import { useStore, mapGetters} from "vuex";
import {computed} from "vue";
import { auth } from '../firebaseConfig'
import ApiKeyGenerator from "@/components/ApiKeyGenerator.vue";
import navbar from "@/components/Navbar";
import { getAuth} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import Appfooter from "@/components/AppFooter.vue"



export default {
    name: "DashboardComponent",
    data() {
      return {
       isDesktop: false
     }

  },
    setup() {
        const store = useStore();
        auth.onAuthStateChanged(user => {
            store.dispatch("fetchUser", user);
        });
        const user = computed(() => {
            return store.getters.user;
        });
        return { user};
    },
    methods: {
  async fetchApiUsage() {
    const auth = getAuth();
    const firestore = getFirestore();
    const user = auth.currentUser;

    if (user) {
      const userRef = doc(firestore, 'users', user.uid);
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          // Extract the api_usage_count value from the document
          const apiUsageCount = docSnap.data().api_usage_count || 0;
          // Dispatch the value to your Vuex store
          this.$store.dispatch('updateApiUsageCount', apiUsageCount);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    }
  },
  checkViewport() {
       this.isDesktop = window.matchMedia('(min-width: 1200px)').matches;
     }
},

computed: {
  ...mapGetters(['apiUsageCount', 'user']),
  availableApi() {
    return 100 - this.apiUsageCount;
  }
},
  mounted() {
    this.checkViewport();
     window.addEventListener('resize', this.checkViewport);
   },
   beforeUnmount() { // changed from beforeDestroy
     window.removeEventListener('resize', this.checkViewport);},

    components: { ApiKeyGenerator, navbar, Appfooter}
};
</script>