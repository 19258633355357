<template>
  <section class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <div class="flex align-items">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img class="w-14 h-14 mr-2" src="../assets/logo_app.png" alt="logo">
         Airtable Exporter
      </a>
    </div>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Create an account
              </h1>
<!--               <form class="space-y-4 md:space-y-6" @submit.prevent="sendSignInLink">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" autofocusv-model="email" v-model="email">
                  </div>
               <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" v-model="password">
                  </div> 
                  <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="terms" class="font-light text-gray-500 dark:text-gray-300">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                      </div>
                  </div>
                  <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign up with magic link</button>
                  <div v-if="message" class="mt-4 text-green-600">{{ message }}</div>
                  <div v-if="error" class="mt-4 text-red-600">{{ error }}</div>
                  <div class="flex items-center justify-center my-4">
                  <div class="flex-grow border-t border-gray-300"></div>
                  <span class="flex-shrink mx-4 text-gray-300">or</span>
                  <div class="flex-grow border-t border-gray-300"></div>
                </div> </form>-->
                  <button @click="signInWithGoogle" type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign up with Google</button>
                  <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Already have an account? <a href="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>
                  </p>
       <!--tu mozna dać </form>-->
              <div v-if="error" class="alert alert-danger">{{ error }}</div>
          </div>
      </div>
  </div>
</section>
</template>


<script>

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { doc, setDoc, getDoc, getFirestore } from 'firebase/firestore'



export default {
  name: "RegisterComponent",
  setup() {
    const name = ref('')
    const email = ref('')
    const password = ref('')
    const error = ref(null)

    const store = useStore()
    const router = useRouter()

    const register = async () => {
      try {
        await store.dispatch('register', {
          email: email.value,
          password: password.value,
          name: name.value
        })
        router.push('/')
      } catch (err) {
        error.value = err.message
      }
    }

    const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const firestore = getFirestore(); // Make sure you have a reference to Firestore

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Correctly reference the user document in Firestore
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      // Create a new user document
      await setDoc(userDocRef, {
        displayName: user.displayName || '',
        email: user.email,
        createdAt: new Date(),
        api_usage_count: 50,
        current_valid_key:''
        // any other user fields you wish to include
      });
    }

    // Update Vuex store and redirect
    store.commit('SET_USER', user);
    router.push('/');
  } catch (err) {
    error.value = err.message;
  }
};



    return { register, signInWithGoogle, name, email, password, error }
  }
}
</script>