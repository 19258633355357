<template>
<footer class="mt-20 p-4 sm:p-6 bg-gray-100 rounded-tl-50 rounded-tr-50">
    <div class="mx-auto max-w-screen-xl pt-4">
        <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
                <a href="/" class="flex items-center">
                    <img src="../assets/ae-logo.png" class="mr-3 w-32" alt="App Logo" />
                </a>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-black-300 uppercase ">Resources</h2>
                    <ul class="text-gray-400">
                        <li class="mb-4">
                            <a href="https://bogumil-luc.gitbook.io/airtable/" class="hover:underline">Docs</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold uppercase text-black-300">Follow me</h2>
                    <ul class="text-gray-400 ">
                        <li class="mb-4">
                            <a href="https://github.com/miekki-jerry" class="hover:underline ">Github</a>
                        </li>
                        <li class="mb-4">
                            <a href="https://twitter.com/bogumil_luc" class="hover:underline ">X</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold uppercase text-black-300">Legal</h2>
                    <ul class="text-gray-400 ">
                        <li class="mb-4">
                            <a href="https://bogumil.notion.site/Privacy-Policy-6f879a564da34cc4a8d06bff2a58720a?pvs=4" class="hover:underline">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm text-gray-400 sm:text-center ">© 2024 <a href="https://airexporter.webflow.io/" class="hover:underline">TableID</a>
            </span>
        </div>
    </div>
</footer>
</template>

<script>


export default {
name: "AppFooter",
setup() {

  },
  components: {}

};
</script>