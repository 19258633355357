import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Dashboard from '../views/Dashboard.vue';
import PricingPage from '../views/Pricing.vue'
import { auth } from '../firebaseConfig'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard, 
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: PricingPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const waitForAuth = new Promise((resolve) => {
      auth.onAuthStateChanged(resolve);
    });
  
    waitForAuth.then((user) => {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!user) {
          next('/login');
        } else {
          next();
        }
      } else {
        next();
      }
    });
  });

export default router;