<template>

<div class="bg-gray-100 px-8 py-4 rounded-15 mb-8">
  <p class="text-lg">Copy this token and paste as a <strong>x-api-key</strong> in header</p>


<!-- dobrze zrobione z ikonkami<div class="flex mt-2">
  <span @click="generateAndSaveApiKey" class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600 cursor-pointer">
    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"/>
  </svg>
  </span>
  <input v-on:focus="$event.target.select()" ref="clone" readonly :value="apiKey" type="text" id="website-admin" class="rounded-none rounded-e-e bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
  <span @click="copy" class="inline-flex items-center px-3 text-sm text-gray-900 bg-black border rounded-e-lg border-gray-300 rounded-md-s dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600 cursor-pointer">
  <svg class="w-4 h-4 text-white dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 18a.969.969 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9l4-4m-4 5h5m3-4h5V1m5 1v12a.97.97 0 0 1-.933 1H9.933A.97.97 0 0 1 9 14V5l4-4h5.067A.97.97 0 0 1 19 2Z"/>
  </svg>
  </span>
</div> -->



<div class="flex mt-2 h-16">
  <input v-on:focus="$event.target.select()" ref="clone" readonly :value="apiKey" type="password" id="website-admin" class="pl-4 rounded-tl-15 rounded-bl-15 bg-white border text-gray-400 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
  <span @click="copy" class="inline-flex items-center px-3 text-sm text-gray-900 bg-black border rounded-e-15 border-gray-300 rounded-md-s dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600 cursor-pointer">
  <svg class="w-8 h-6 text-white dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 18a.969.969 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V9l4-4m-4 5h5m3-4h5V1m5 1v12a.97.97 0 0 1-.933 1H9.933A.97.97 0 0 1 9 14V5l4-4h5.067A.97.97 0 0 1 19 2Z"/>
  </svg>
  </span>
</div>






</div>
<div class="flex justify-center items-center">
     <button @click="generateAndSaveApiKey" class="rounded-full bg-black text-xl text-white px-5 py-3">Generate new key</button>
 </div>


<!-- <div v-if="message">{{ message }}</div> -->

  </template>
  
  <script>
  // Make sure to install Firebase via npm or yarn and then import as shown below
  import { getAuth } from 'firebase/auth';
  import { getFirestore, doc, getDoc, updateDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
  
  export default {
    data() {
      return {
        apiKey: null,
        message: '',
        text: ''
      }
    },

    methods: {
      copy() {
     this.$refs.clone.type = 'text';
     this.$refs.clone.select();
     document.execCommand('copy');
     this.$refs.clone.type = 'password';
   },
      generateApiKey(length = 30) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        let result = ''
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return result
      },
      
      async generateAndSaveApiKey() {
        const auth = getAuth();
        const firestore = getFirestore();
        try {
          const userId = auth.currentUser.uid;
          const newApiKey = this.generateApiKey()
          this.apiKey = newApiKey
  
          const userRef = doc(firestore, 'users', userId)
          const apiKeysCol = collection(userRef, 'api_keys')
          await addDoc(apiKeysCol, { key: newApiKey, creation_time: serverTimestamp() })
          await updateDoc(userRef, { current_valid_key: newApiKey })
  
          this.message = 'API Key generated and saved successfully.'
        } catch (error) {
          this.message = `Error generating API Key: ${error.message}`
          console.error(error)
        }
      },
      async fetchLastApiKey() {
      const auth = getAuth();
      const firestore = getFirestore();
      try {
        const userId = auth.currentUser.uid;
        const userRef = doc(firestore, 'users', userId)
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          this.apiKey = docSnap.data().current_valid_key;
        } else {
          this.message = 'No API Key found.';
        }
      } catch (error) {
        this.message = `Error fetching API Key: ${error.message}`;
        console.error(error);
      }
    }
  },
  mounted() {
    this.fetchLastApiKey();
  }
    
  }
  
  </script>
  